import { useState, useMemo } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useLocation, useMatch, useNavigate, useSearchParams } from "react-router-dom";
import classnames from "classnames";

import {
  BulkActionButton,
  BulkActionClearButton,
  BulkActionSeparator,
  useBulkActions,
} from "common/core/bulk_actions/common";
import { BulkActionBar } from "common/core/bulk_actions";
import { ZIP_ACCEPTED } from "util/uploader";
import { encodeSearchParams } from "util/location";
import { openUrlInNewTab } from "util/window";
import { useQuery, useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { b, usePrependedDocumentTitle } from "util/html";
import { usePermissions } from "common/core/current_user_role";
import Button from "common/core/button";
import Icon from "common/core/icon";
import Link from "common/core/link";
import LoadingIndicator from "common/core/loading_indicator";
import { OrganizationDocumentTemplateState } from "graphql_globals";
import { SearchField } from "common/core/search_field";
import Uploader from "common/core/uploader";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import AlertMessage from "common/core/alert_message";
import { useActiveOrganization } from "common/account/active_organization";
import { FilterDropdown, FilterMultiOption } from "common/dashboard/filter_dropdown";
import { SelectedFiltersLabel } from "common/dashboard/filter_dropdown/common";
import TablePagination from "common/core/table/pagination";
import { ROUTE as TEMPLATES_ROUTE } from "common/tools/document_templates";
import { TEMPLATE_SUPPORT_URL } from "constants/support";
import { useMoveTools } from "util/feature_detection";
import { ToolsRedirect } from "util/routes";

import TemplateManager from "./manager";
import TemplateUploader from "./uploader";
import TemplatesGetStarted from "./get_started";
import DeleteTemplatesModal from "./delete_templates_modal";
import { EasylinksErrorModal } from "./easylinks_error_modal";
import TemplatesQuery, {
  type Templates,
  type Templates_organization_Organization as Organization,
  type Templates_organization_Organization_templates_edges_template as Template,
  type Templates_organization_Organization_templates_edges as TemplateEdges,
} from "./templates_query.graphql";
import CreateOrganizationDocumentTemplateMutation from "./create_organization_document_template_mutation.graphql";
import ExportSelectedOrganizationDocumentTemplatesMutation from "./export_selected_organization_document_templates_mutation.graphql";
import DeleteSelectedOrganizationDocumentTemplatesMutation from "./delete_selected_organization_document_templates_mutation.graphql";
import ImportOrganizationDocumentTemplatesMutation from "./import_organization_document_templates_mutation.graphql";
import Styles from "./index.module.scss";

const MAX_ZIP_SIZE = 200 * 1024 * 1024; // 200 megabits
const EASYLINK_USING_TEMPLATE_ERROR = "Business::Errors::EasylinkUsingTemplate";
const ITEMS_PER_PAGE = 100;

const MESSAGES = defineMessages({
  title: { id: "775d4c34-cb7f-45d3-82c2-774917f09d54", defaultMessage: "Document templates" },
  searchPlaceholder: {
    id: "0e638ba6-6be3-4202-a8c1-99dfae9ad72b",
    defaultMessage: "Search by template name",
  },
  searchLabel: {
    id: "39b6f417-80f5-44c9-a628-00e96fa16be5",
    defaultMessage: "Search for a template",
  },
});

function updateTemplates(data: Templates, updater: (edges: TemplateEdges[]) => TemplateEdges[]) {
  const organization = data.organization as Organization;

  const updatedEdges = updater(organization.templates.edges);
  return {
    ...data,
    organization: {
      ...organization,
      templates: {
        ...organization.templates,
        edges: updatedEdges,
        totalCount: updatedEdges.length,
      },
    },
  };
}

function templateDashboardDeserializer(queryArgs: URLSearchParams) {
  const search = queryArgs.get("search");
  return {
    search,
  };
}

/** This private component is the start/top of the presentation components */
function OrganizationTemplatesInner({
  organization,
  dashboardMode,
}: Templates & { dashboardMode?: boolean }) {
  const { id: organizationId, templates } = organization as Organization;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { search: deserializedSearch } = useMemo(
    () => templateDashboardDeserializer(searchParams),
    [searchParams],
  );
  const { search, pathname } = location;
  const moveTools = useMoveTools();
  // BIZ-7111: Always use the new hardcoded tools path.
  const basePath = moveTools ? `tools/${TEMPLATES_ROUTE}` : pathname.split("/")[1];
  const [searchQuery, setSearchQuery] = useState(deserializedSearch || "");
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page") || 1));
  const [showDeleteTemplatesModal, setShowDeleteTemplatesModal] = useState(false);
  const [templatesForEasylinkError, setTemplatesForEasylinkError] = useState<Template[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedFilterStatuses, setSelectedFilterStatuses] = useState(new Set());
  const [statusFilterOpen, setStatusFilterOpen] = useState(false);
  const intl = useIntl();

  const items = useMemo(() => templates.edges.map((template) => template.template), [templates]);

  usePrependedDocumentTitle(intl.formatMessage(MESSAGES.title));

  const {
    toggleItem,
    clearAllItems,
    selectedItemCount,
    selectedItemIdsSet,
    setSelectedItems,
    selectedItemIdsArray,
  } = useBulkActions(items);

  const createTemplate = useMutation(CreateOrganizationDocumentTemplateMutation);
  const importOrganizationDocumentTemplates = useMutation(
    ImportOrganizationDocumentTemplatesMutation,
  );
  const exportSelectedTemplate = useMutation(ExportSelectedOrganizationDocumentTemplatesMutation);
  const deleteSelectedTemplate = useMutation(DeleteSelectedOrganizationDocumentTemplatesMutation);

  const handleSearchQueryChange = ({ value }: { value: string }) => {
    setSearchQuery(value);
    setCurrentPage(1);
    navigate(`/${basePath}?search=${value}`);
  };

  const handleClearSearchQuery = () => {
    setSearchQuery("");
    navigate(`/${basePath}`);
  };

  const handleUploadSuccess = (template: Template) => {
    navigate(`/${basePath}/${template.id}/new${search}`);
  };

  const selectedTemplates = (ids: string[]) => {
    return items.filter((template) => ids.includes(template.id));
  };

  const handleImportTemplates = (
    uploadedFile: {
      s3Key: string;
      type: string;
      filename: string;
    },
    successCallback: () => void,
  ) => {
    return importOrganizationDocumentTemplates({
      variables: { input: { organizationId, fileHandle: uploadedFile.s3Key } },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: (
            <FormattedMessage
              id="731e193c-c4b3-48f5-9753-1eeddb7ebf6f"
              defaultMessage="Please check back in ~10 minutes and refresh the page to see the imported templates."
            />
          ),
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          position: "topCenter",
        });
      })
      .finally(() => {
        successCallback();
      });
  };

  const handleExportSelectedTemplates = () => {
    exportSelectedTemplate({
      variables: { input: { ids: selectedItemIdsArray } },
    }).then(() => {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: (
          <FormattedMessage
            id="731e193c-c4b3-48f5-9753-1eeddb7ebf8d"
            defaultMessage="<b>We've started exporting {templateCount} {templateCount, plural, one {template} other {templates}}.</b> This can take up to 10 minutes, you will be emailed once we have finished."
            values={{
              templateCount: selectedItemIdsArray.length,
              b,
            }}
          />
        ),
        position: "topCenter",
      });
    });
  };

  const handleDeleteTemplates = (ids: string[]) => {
    deleteSelectedTemplate({
      variables: { input: { ids } },
      update(cacheProxy) {
        const data = cacheProxy.readQuery<Templates>({
          query: TemplatesQuery,
          variables: { organizationId },
        });
        const newData = updateTemplates(data!, (edges) =>
          edges.filter((edge) => !ids.includes(edge.template.id)),
        );
        cacheProxy.writeQuery({ query: TemplatesQuery, data: newData });
      },
    })
      .then((response) => {
        const deleteCount =
          response.data?.deleteSelectedOrganizationDocumentTemplates?.organizationTemplateIds
            .length;
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: (
            <FormattedMessage
              id="21628701-3147-435f-965b-0a4502b18e8c"
              defaultMessage="{deleteCount} {deleteCount, plural, one {template} other {templates}} deleted"
              values={{ deleteCount }}
            />
          ),
        });
        navigate(`/${basePath}${search}`);
        setShowDeleteTemplatesModal(false);
      })
      .catch((error) => {
        if (
          isGraphQLError(error) &&
          error.graphQLErrors[0].message === EASYLINK_USING_TEMPLATE_ERROR
        ) {
          setTemplatesForEasylinkError(selectedTemplates(ids));
        }
      });
  };

  const handleSelectTemplateLibrary = () => {
    const allTemplates = templates.edges.reduce((map, templateEdge: TemplateEdges) => {
      map.set(templateEdge.template.id, templateEdge.template);
      return map;
    }, new Map<string, Template>());

    setSelectedItems(allTemplates);
  };

  const handleChangeFilterStatus = (newStatus: string) => {
    const newSelectedStatuses = new Set(selectedFilterStatuses.values());
    if (newStatus === "Select All") {
      newSelectedStatuses.size < 2
        ? [
            OrganizationDocumentTemplateState.ACTIVE,
            OrganizationDocumentTemplateState.DISABLED,
            OrganizationDocumentTemplateState.DRAFT,
          ].map((status) => newSelectedStatuses.add(status))
        : newSelectedStatuses.clear();
    } else if (newSelectedStatuses.has(newStatus)) {
      newSelectedStatuses.delete(newStatus);
    } else {
      newSelectedStatuses.add(newStatus);
    }
    setSelectedFilterStatuses(newSelectedStatuses);
  };

  const { hasPermissionFor } = usePermissions();
  const userCanEditPresetTemplates = hasPermissionFor("templatePreset");
  const businessTemplatesUser = hasPermissionFor("templateCreate");

  const filteredTemplatesByStatus =
    selectedFilterStatuses.size === 0
      ? templates.edges
      : templates.edges.filter(({ template }) =>
          selectedFilterStatuses.has(template.activationState),
        );

  const filteredTemplatesBySearch =
    searchQuery !== ""
      ? filteredTemplatesByStatus.filter(
          ({ template }) =>
            template.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (userCanEditPresetTemplates &&
              template.displayName?.toLowerCase().includes(searchQuery.toLowerCase())),
        )
      : filteredTemplatesByStatus;

  const changePage = (pg: number) => {
    setCurrentPage(pg);
    const newSearch = encodeSearchParams(searchParams, { page: pg.toString() });
    navigate(`/${basePath}?${newSearch}`);
  };

  const hasTemplates = templates.totalCount > 0;
  const lastPage = Math.ceil(filteredTemplatesBySearch.length / ITEMS_PER_PAGE);
  const canPreviousPage = currentPage > 1;
  const canNextPage = currentPage < lastPage;
  const nextPage = () => canNextPage && changePage(currentPage + 1);
  const previousPage = () => canPreviousPage && changePage(currentPage - 1);
  const startIndex =
    filteredTemplatesBySearch.length === 0
      ? 0
      : filteredTemplatesBySearch.length < ITEMS_PER_PAGE
        ? 1
        : (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex =
    filteredTemplatesBySearch.length < ITEMS_PER_PAGE
      ? filteredTemplatesBySearch.length
      : currentPage * ITEMS_PER_PAGE > templates.totalCount
        ? templates.totalCount
        : currentPage * ITEMS_PER_PAGE;

  const editRoute = ({ id }: { id: string }) => `/${basePath}/${id}/edit${search}`;
  const sortedTemplates = filteredTemplatesBySearch
    .map(({ template }) => ({
      id: template.id,
      name: template.name,
      displayName: template.displayName,
      permalink: template.permalink,
      preset: template.preset,
      activationState: template.activationState,
      updatedAt: template.updatedAt,
      easylinks: template.easylinks,
      createdByProof: template.createdByProof,
    }))
    .sort((a, b) => a.updatedAt.localeCompare(b.updatedAt))
    .reverse()
    .slice(ITEMS_PER_PAGE * (currentPage - 1), ITEMS_PER_PAGE * currentPage);

  /*  These next two hooks are custom to allow users to select all visible templates rather than the entire
  template library. If all visible templates are selected, users can then utilize handleSelectTemplateLibrary */

  const toggleAllItems = () => {
    const items =
      selectedItemIdsSet.size < sortedTemplates.length
        ? sortedTemplates.reduce((map, template) => {
            map.set(template.id, template);
            return map;
          }, new Map<string, Template>())
        : new Map<string, Template>();

    setSelectedItems(items);
  };

  const selectAllCheckboxState = (): "unchecked" | "checked" | "partial" => {
    if (selectedItemIdsSet.size === 0) {
      return "unchecked";
    } else if (selectedItemIdsSet.size < sortedTemplates.length) {
      return "partial";
    }
    return "checked";
  };

  const showPreDeletionModal = () => {
    const templatesHaveActiveEasylinks = selectedTemplates(selectedItemIdsArray).some((template) =>
      template.easylinks.some((easylink) => easylink.active),
    );
    if (templatesHaveActiveEasylinks) {
      setTemplatesForEasylinkError(selectedTemplates(selectedItemIdsArray));
    } else {
      setShowDeleteTemplatesModal(true);
    }
  };

  const createButton = (
    <TemplateUploader
      title={
        <FormattedMessage
          id="ba4b7dd5-2de9-4e8e-be28-57b642e78cbe"
          defaultMessage="Create new template +"
        />
      }
      buttonStyleProps={{
        variant: "primary",
        buttonColor: "action",
      }}
      onUploadSuccess={handleUploadSuccess}
      createTemplate={createTemplate}
      organizationId={organizationId}
      isPreset={userCanEditPresetTemplates}
    />
  );

  // HACK: Temporary solution to force redirect to the new tools path if appropriate.
  if (!useMatch({ path: "tools", end: false }) && moveTools) {
    return ToolsRedirect();
  }

  return (
    <div
      className={classnames(
        Styles.organizationTemplates,
        !dashboardMode && Styles.organizationTemplatesStandalone,
      )}
    >
      <div className={Styles.organizationTemplatesHeader}>
        <p
          className={Styles.organizationTemplatesHeaderTitle}
          data-automation-id="templates-header-title"
        >
          <FormattedMessage
            id="c6b9f1ca-b0cc-4606-bb9c-5d655626a791"
            defaultMessage="Document templates"
          />
        </p>
        {userCanEditPresetTemplates || businessTemplatesUser ? (
          <div className={Styles.organizationTemplatesHeaderButtons}>
            <Uploader
              className="uploader"
              createFile={handleImportTemplates}
              acceptedFileTypes={ZIP_ACCEPTED}
              allowMultiple
              onUploadFailure={(err) => {
                const error = err?.[0]?.specifics ?? (
                  <FormattedMessage
                    id="3b066576-a496-4717-9cdb-40cf251970e0"
                    defaultMessage="There was an error importing"
                  />
                );
                setError(error);
              }}
              filesizeLimit={MAX_ZIP_SIZE}
              buttonStyleProps={{ variant: "secondary" }}
              title={
                <FormattedMessage
                  id="dcaaa495-4e74-4364-aabc-a231370bb40d"
                  defaultMessage="Import <space></space> {icon}"
                  values={{
                    space: () => <p>&nbsp;&nbsp;&nbsp; </p>,
                    icon: <Icon name="arrow-up-square" />,
                  }}
                />
              }
              automationId="import-templates"
            />
            {hasTemplates && createButton}
          </div>
        ) : (
          <TemplateUploader
            title={
              <FormattedMessage
                id="9713c5ec-98b9-4321-81e1-a4eaa37d4f41"
                defaultMessage="Create new template +"
              />
            }
            buttonStyleProps={{ variant: "primary", buttonColor: "action" }}
            onUploadSuccess={handleUploadSuccess}
            createTemplate={createTemplate}
            organizationId={organizationId}
          />
        )}
      </div>
      {hasTemplates ? (
        <div className={Styles.organizationTemplatesSubHeader}>
          <FormattedMessage
            id="fb966219-b029-4f1f-a99b-99e6787ae2b8"
            defaultMessage="Document templates allow you to upload and tag your documents once, then save them as a template for future use."
          />
          &nbsp;
          <Link href={TEMPLATE_SUPPORT_URL}>
            <FormattedMessage
              id="a51c373a-ee3e-4770-82ed-58915f0fbcdc"
              defaultMessage="Learn more"
            />
          </Link>
        </div>
      ) : (
        <div className={Styles.organizationTemplatesSubHeader}>
          <FormattedMessage
            id="fb966219-b029-4f1f-a99b-99e6787ae2b8"
            defaultMessage="This is where you can view and manage your document templates."
          />
        </div>
      )}
      {error && (
        <AlertMessage
          kind="danger"
          centerText
          onClose={() => {
            setError(null);
          }}
        >
          <div className={Styles.organizationTemplatesError}>{error}</div>
        </AlertMessage>
      )}
      <div
        className={
          moveTools ? Styles.organizationTemplatesBody : Styles.deprecatedOrganizationTemplatesBody
        }
      >
        {hasTemplates ? (
          <>
            <div>
              <div className={Styles.organizationTemplatesRow}>
                <div className={Styles.organizationTemplatesSearch}>
                  <SearchField
                    onChange={handleSearchQueryChange}
                    value={searchQuery}
                    placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
                    aria-label={intl.formatMessage(MESSAGES.searchLabel)}
                    searchOnClear
                  />
                </div>
                {userCanEditPresetTemplates ? (
                  <div>
                    <FilterDropdown
                      isOpen={statusFilterOpen}
                      setIsOpen={setStatusFilterOpen}
                      hasSelection={Boolean(selectedFilterStatuses.size)}
                      unselectedLabel={
                        <FormattedMessage
                          id="a1817457-ed90-4ed5-885d-f5a5e4bbf0e4"
                          defaultMessage="Select All"
                        />
                      }
                      label={
                        <SelectedFiltersLabel
                          count={selectedFilterStatuses.size}
                          label={
                            <FormattedMessage
                              id="298be429-f6ea-4031-b8a3-bf8e2ef0f89a"
                              defaultMessage="Status"
                            />
                          }
                        />
                      }
                      onSelectAll={() => handleChangeFilterStatus("Select All")}
                      disabled={false}
                    >
                      <FilterMultiOption
                        selected={selectedFilterStatuses.has(
                          OrganizationDocumentTemplateState.ACTIVE,
                        )}
                        onChange={() =>
                          handleChangeFilterStatus(OrganizationDocumentTemplateState.ACTIVE)
                        }
                        disabled={false}
                      >
                        Active
                      </FilterMultiOption>
                      <FilterMultiOption
                        selected={selectedFilterStatuses.has(
                          OrganizationDocumentTemplateState.DRAFT,
                        )}
                        onChange={() =>
                          handleChangeFilterStatus(OrganizationDocumentTemplateState.DRAFT)
                        }
                        disabled={false}
                      >
                        Draft
                      </FilterMultiOption>
                      <FilterMultiOption
                        selected={selectedFilterStatuses.has(
                          OrganizationDocumentTemplateState.DISABLED,
                        )}
                        onChange={() =>
                          handleChangeFilterStatus(OrganizationDocumentTemplateState.DISABLED)
                        }
                        disabled={false}
                      >
                        Disabled
                      </FilterMultiOption>
                    </FilterDropdown>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={Styles.organizationTemplatesRow}>
                <div className={Styles.organizationTemplatesBulkActions}>
                  {selectedItemCount > 0 && (
                    <BulkActionBar itemCount={selectedItemCount}>
                      {selectedItemCount === ITEMS_PER_PAGE ? (
                        <BulkActionButton
                          withIcon={{ name: "select-all", placement: "left" }}
                          automationSuffix="select-all"
                          onClick={() => handleSelectTemplateLibrary()}
                          label={
                            <FormattedMessage
                              id="ee123913-9478-47f4-89b7-8d9228b7b68a"
                              defaultMessage={`Select all {totalCount} templates`}
                              values={{ totalCount: templates.totalCount }}
                            />
                          }
                        />
                      ) : (
                        <BulkActionClearButton onClick={clearAllItems} />
                      )}
                      <BulkActionSeparator />

                      {(userCanEditPresetTemplates || businessTemplatesUser) && (
                        <BulkActionButton
                          buttonColor="action"
                          withIcon={{ name: "arrow-down-square", placement: "left" }}
                          automationSuffix="export"
                          onClick={handleExportSelectedTemplates}
                          label={
                            <FormattedMessage
                              id="7f6ae851-3f15-48cc-9291-451af84e7c20"
                              defaultMessage="Export"
                            />
                          }
                        />
                      )}

                      <BulkActionButton
                        buttonColor="danger"
                        withIcon={{ name: "delete", placement: "left" }}
                        automationSuffix="delete"
                        onClick={showPreDeletionModal}
                        label={
                          <FormattedMessage
                            id="33b913a8-83bd-44be-a4c7-797b748a6c8c"
                            defaultMessage="Delete"
                          />
                        }
                      />
                    </BulkActionBar>
                  )}
                </div>
                <TablePagination
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  totalCount={filteredTemplatesBySearch.length}
                />
              </div>
            </div>
            <TemplateManager
              templates={sortedTemplates}
              selectedTemplates={selectedItemIdsSet}
              toggleTemplate={toggleItem}
              toggleAllTemplates={toggleAllItems}
              clearAllTemplates={clearAllItems}
              selectAllCheckboxState={selectAllCheckboxState}
              onDeleteTemplates={handleDeleteTemplates}
              editRoute={editRoute}
              userCanEditPresetTemplates={userCanEditPresetTemplates}
              searchQuery={searchQuery}
              clearSearchQuery={handleClearSearchQuery}
            />
          </>
        ) : (
          <>
            <TemplatesGetStarted>
              <div className={Styles.organizationTemplatesEmptyStateButtons}>
                {createButton}
                <Button
                  buttonColor="action"
                  variant="secondary"
                  fullwidth
                  onClick={() => openUrlInNewTab(TEMPLATE_SUPPORT_URL)}
                >
                  <FormattedMessage
                    id="24d26028-c672-4c53-b6b2-ea6cdd2d910c"
                    defaultMessage="How it works"
                  />
                  <Icon name="arrow-up-right-square" className={Styles.emptyStateButtonIcon} />
                </Button>
              </div>
            </TemplatesGetStarted>
          </>
        )}
      </div>
      {showDeleteTemplatesModal && (
        <DeleteTemplatesModal
          numberOfTemplates={selectedItemIdsSet.size}
          onClose={() => setShowDeleteTemplatesModal(false)}
          onDelete={() => handleDeleteTemplates(selectedItemIdsArray)}
        />
      )}
      {templatesForEasylinkError.length > 0 && (
        <EasylinksErrorModal
          templates={templatesForEasylinkError}
          onClose={() => setTemplatesForEasylinkError([])}
        />
      )}
    </div>
  );
}

export function OrganizationTemplates({ dashboardMode }: { dashboardMode?: boolean }) {
  const [activeOrganizationId] = useActiveOrganization();
  // BIZ-7224 Add backend-managed pagination
  const { data, loading } = useQuery(TemplatesQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <OrganizationTemplatesInner
      organization={data!.organization}
      viewer={data!.viewer}
      dashboardMode={dashboardMode}
    />
  );
}
