import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import ProofSeal from "assets/images/logos/proof-seal.svg";
import Button from "common/core/button";
import { LongFormattedDateTime } from "common/core/format/date";
import { OrganizationDocumentTemplateState } from "graphql_globals";
import { DeprecatedCheckbox } from "common/form/inputs/checkbox";

import { TemplateMeatballMenu } from "./meatball_menu";
import DeleteTemplatesModal from "../delete_templates_modal";
import { EasylinksErrorModal } from "../easylinks_error_modal";

const MESSAGES = defineMessages({
  toggleAll: {
    id: "51028615-dfa3-4d28-88a6-4456d823d7c4",
    defaultMessage: "Toggle All Selections",
  },
});

function templateStatus(template) {
  if (template.activationState === OrganizationDocumentTemplateState.ACTIVE) {
    return <FormattedMessage id="f8b5586e-30ee-4e8b-aac2-eb0b486f1e1b" defaultMessage="Active" />;
  } else if (template.activationState === OrganizationDocumentTemplateState.DRAFT) {
    return <FormattedMessage id="db6a1d3d-08d4-4730-95c6-c1a77137812f" defaultMessage="Draft" />;
  }
  return <FormattedMessage id="409a80e7-1e69-407a-b1ff-d28e7f46b093" defaultMessage="Disabled" />;
}

function Row({
  template,
  selectedTemplates,
  toggleTemplate,
  onEditTemplate,
  onDeleteTemplate,
  userCanEditPresetTemplates,
  intl,
}) {
  const isSelected = selectedTemplates.has(template.id);
  return (
    <tr
      data-document-id={template.id}
      data-automation-id="template-manager-row"
      className={isSelected ? "is-selected" : undefined}
      onClick={() => {
        onEditTemplate(template.id);
      }}
    >
      <td
        width="44px"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DeprecatedCheckbox
          automationId="select-templates-checkbox"
          checked={isSelected}
          onChange={() => toggleTemplate(template.id)}
        />
      </td>
      <td className="TemplateManager__truncate" data-automation-id="template-manager-filename">
        <div className="TemplateManager-templatename">
          {template.createdByProof && (
            <img
              className="proof-seal"
              src={ProofSeal}
              alt={intl.formatMessage({
                id: "4d8a3d26-01f1-44be-92ae-b75e7e11a4a9",
                defaultMessage: "Template provided by Proof",
              })}
            />
          )}
          <div>
            {template.name}
            {userCanEditPresetTemplates && template.displayName && (
              <p className="TemplateManager--display-name">{template.displayName}</p>
            )}
          </div>
        </div>
      </td>
      <td data-automation-id="document-updated-at">
        <LongFormattedDateTime value={template.updatedAt} />
      </td>
      {userCanEditPresetTemplates && (
        <>
          <td>{templateStatus(template)}</td>
        </>
      )}
      <td
        className="TemplateManager__meatball-menu"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {userCanEditPresetTemplates || !template.preset ? (
          <TemplateMeatballMenu
            automationId={`${template.id}-template-actions`}
            template={template}
            onEdit={onEditTemplate}
            onDelete={onDeleteTemplate}
          />
        ) : (
          <FormattedMessage id="3d2348cb-6343-474b-95f2-0147971375fd" defaultMessage="Read-only" />
        )}
      </td>
    </tr>
  );
}

// TODO: DAS-695 convert to use common Table component
class TemplateManager extends Component {
  state = {
    templateToDelete: null,
    modal: null,
  };

  handleEditTemplate = (templateId) => {
    const { navigate, editRoute, searchQuery } = this.props;
    navigate(editRoute({ id: templateId }), { state: searchQuery, replace: false });
  };

  handleShowDeleteConfirmationModal = (template) => {
    if (template.easylinks.some((easylink) => easylink.active)) {
      this.setState({ templateToDelete: template, modal: "easylink-error" });
    } else {
      this.setState({ templateToDelete: template, modal: "delete-confirmation" });
    }
  };

  render() {
    const {
      templates,
      selectedTemplates,
      toggleTemplate,
      toggleAllTemplates,
      selectAllCheckboxState,
      intl,
      onDeleteTemplates,
      userCanEditPresetTemplates,
      clearSearchQuery,
    } = this.props;

    const allItemsChecked = selectAllCheckboxState() === "checked";
    const managerClass = "TemplateManager";

    return (
      <div className={managerClass}>
        <table>
          <thead>
            <tr>
              <th width="44px">
                <DeprecatedCheckbox
                  aria-label={intl.formatMessage(MESSAGES.toggleAll)}
                  checked={allItemsChecked}
                  onChange={toggleAllTemplates}
                  indeterminate={selectAllCheckboxState() === "partial"}
                />
              </th>
              <FormattedMessage
                id="5e80fb35-f8f4-4740-9630-f2fa1a47962e"
                defaultMessage="Name"
                tagName="th"
              />
              <FormattedMessage
                id="adf84466-7aa0-42a9-acf0-7db671d3d9c7"
                defaultMessage="Updated At"
                tagName="th"
              />
              {userCanEditPresetTemplates && (
                <>
                  <FormattedMessage
                    id="000d1829-9e86-47ef-b02b-fb0346b24687"
                    defaultMessage="Status"
                    tagName="th"
                  />
                </>
              )}
              {/* Extra th so that last (single action) column will also have bottom border in header */}
              <th />
            </tr>
          </thead>
          <tbody>
            {templates.length ? (
              templates.map((template) => (
                <Row
                  key={template.id}
                  template={template}
                  toggleTemplate={toggleTemplate}
                  onEditTemplate={this.handleEditTemplate}
                  onDeleteTemplate={this.handleShowDeleteConfirmationModal}
                  userCanEditPresetTemplates={userCanEditPresetTemplates}
                  selectedTemplates={selectedTemplates}
                  intl={intl}
                />
              ))
            ) : (
              <tr>
                <td colSpan={userCanEditPresetTemplates ? 6 : 4} className="empty-state-cell">
                  <div className="empty-state-container">
                    <h5>
                      <FormattedMessage
                        id="0f2963c8-ee61-4dbd-9212-e9ca0f3015e1"
                        defaultMessage="No templates match this search"
                      />
                    </h5>
                    <Button variant="secondary" buttonColor="action" onClick={clearSearchQuery}>
                      <FormattedMessage
                        id="d82b972d-57fc-4d9f-a929-c4cc30d2ac0a"
                        defaultMessage="Clear search"
                      />
                    </Button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {this.state.modal === "delete-confirmation" && (
          <DeleteTemplatesModal
            onClose={() => this.setState({ modal: null })}
            onDelete={() => {
              onDeleteTemplates([this.state.templateToDelete.id]);
              this.setState({ modal: null, templateToDelete: null });
            }}
          />
        )}

        {this.state.modal === "easylink-error" && (
          <EasylinksErrorModal
            templates={[this.state.templateToDelete]}
            onClose={() => this.setState({ modal: null, templateToDelete: null })}
          />
        )}
      </div>
    );
  }
}

TemplateManager.propTypes = {
  templates: PropTypes.array.isRequired,
  /** Function for generating a edit link given an id key **/
  editRoute: PropTypes.func,
  userCanEditPresetTemplates: PropTypes.bool,
  clearSearchQuery: PropTypes.func,
};

export default (props) => <TemplateManager {...props} navigate={useNavigate()} intl={useIntl()} />;
