import { useIntl } from "react-intl";

import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

function LimitedDetails() {
  return <div>Placeholder</div>;
}

function LimitedDetailsWrapper() {
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().transactionDetailsLimitedDetails),
  });

  return <LimitedDetails />;
}

export default LimitedDetailsWrapper;
