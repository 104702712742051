import { FormattedMessage } from "react-intl";

import { SubHeading } from "common/signer/common";
import { Badge } from "common/core/badge";
import Icon from "common/core/icon";
import { Paragraph, Substyle } from "common/core/typography";
import AlertMessage from "common/core/alert_message";
import { RadioGroup, RadioInput } from "common/core/form/option";
import type { IDVInteraction } from "common/identity_verification/interaction";
import { useId } from "util/html";

import { DeviceOption } from "./device_option";
import Styles from "./index.module.scss";

const noop = () => {};

export function DeviceSelector({
  allowDesktopCapture,
  idvInteraction,
  isPs1583Bundle,
  requiresIdPhotos,
  selfieRequired,
}: {
  allowDesktopCapture: boolean;
  idvInteraction: IDVInteraction;
  isPs1583Bundle?: boolean;
  requiresIdPhotos: boolean;
  selfieRequired: boolean;
}) {
  const alertId = useId();
  if (!allowDesktopCapture) {
    return (
      <Paragraph className={Styles.deviceInfo}>
        <FormattedMessage
          id="2191dbe8-162e-431e-9a5c-380f1fbc7db8"
          defaultMessage="We need to confirm your identity by having you use your mobile phone to capture {message}."
          values={{
            // selfie only message
            message: !requiresIdPhotos ? (
              <FormattedMessage
                id="40eb2dc6-9a90-409d-932d-fe9bb867aa35"
                defaultMessage="a selfie"
              />
            ) : // selfie and ID messages
            selfieRequired ? (
              isPs1583Bundle ? (
                <FormattedMessage
                  id="50bb1932-84c6-4df2-a4f7-c268b203364a"
                  defaultMessage="a selfie and photos of your photo ID"
                />
              ) : (
                <FormattedMessage
                  id="f2be7f57-6bbd-484c-b9fc-3a61050af276"
                  defaultMessage="a selfie and photos of your government-issued ID"
                />
              )
            ) : // non-selfie messages
            isPs1583Bundle ? (
              <FormattedMessage
                id="53fcc9ae-3c22-4c26-ab62-06479fe6af9e"
                defaultMessage="photos of your photo ID"
              />
            ) : (
              <FormattedMessage
                id="30175322-4825-4d37-b5f1-32bd8450f7f4"
                defaultMessage="photos of your government-issued ID"
              />
            ),
          }}
        />
      </Paragraph>
    );
  }
  return (
    <>
      <RadioGroup
        className={Styles.deviceOptions}
        inputsClassname={Styles.deviceOptionsInputs}
        label={
          <SubHeading>
            <FormattedMessage
              id="27263572-44f4-4894-a542-02f1b5c8234a"
              defaultMessage="Choose your device"
            />
          </SubHeading>
        }
        horizontal
      >
        <DeviceOption
          label={
            <>
              <Badge kind="success" className={Styles.badge}>
                <FormattedMessage
                  id="3a5231db-9c88-4549-9d56-f912c4286fda"
                  defaultMessage="Recommended"
                />
              </Badge>
              <div className={Styles.deviceIcon}>
                <Icon name="mobile" size="extraLarge" />
              </div>
              <Substyle textStyle="subtitleSmall">
                <FormattedMessage
                  id="625d9cfc-da85-40ba-87b3-73b02883fc91"
                  defaultMessage="Use mobile phone"
                />
              </Substyle>
              <Paragraph textColor="subtle">
                <FormattedMessage
                  id="736368df-e8d8-4443-8962-ee346de5d0a6"
                  defaultMessage="Fast & reliable verification"
                />
              </Paragraph>
            </>
          }
          radio={
            <RadioInput
              value="use-mobile"
              checked={!idvInteraction.configuration.enableDesktopCapture}
              onClick={() => idvInteraction.onConfigurationChange({ enableDesktopCapture: false })}
              onChange={noop}
            />
          }
        />
        <DeviceOption
          data-automation-id="use-this-device-radio"
          label={
            <>
              <div className={Styles.deviceIcon}>
                <Icon name="computer" size="extraLarge" />
              </div>
              <Substyle textStyle="subtitleSmall">
                <FormattedMessage
                  id="008fb696-d731-458e-a83d-fbdbc2fa8872"
                  defaultMessage="Use this device"
                />
              </Substyle>
              <Paragraph textColor="subtle">
                <FormattedMessage
                  id="d56c104b-2cb4-42f5-a7ad-5137fc720711"
                  defaultMessage="Webcam required"
                />
              </Paragraph>
            </>
          }
          radio={
            <RadioInput
              value="use-desktop"
              checked={idvInteraction.configuration.enableDesktopCapture}
              onClick={() => idvInteraction.onConfigurationChange({ enableDesktopCapture: true })}
              onChange={noop}
              aria-describedby={
                idvInteraction.configuration.enableDesktopCapture ? alertId : undefined
              }
            />
          }
        />
      </RadioGroup>

      {idvInteraction.configuration.enableDesktopCapture && (
        <div id={alertId}>
          <AlertMessage kind="warning" className={Styles.deviceOptionsWarning}>
            <FormattedMessage
              id="ebaf6667-0804-46a4-adc9-8310cd848aeb"
              defaultMessage="It's recommended to use a mobile device for capturing ID photos. Desktop capture may not be as reliable."
            />
          </AlertMessage>
        </div>
      )}
    </>
  );
}
