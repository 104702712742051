import { type ReactNode, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { useCloneTransaction } from "util/feature_detection";
import { isNotaryDocumentDownloadProhibited } from "common/notary/capacity";
import { useFeatureFlag } from "common/feature_gating";
import { NetworkStatus, useQuery } from "util/graphql";
import { useAnalyticsContext } from "common/core/manage_analytics_context";
import { getParentPathByOffset, newPathWithPreservedSearchParams } from "util/location";
import { dateComparator } from "util/date";
import { getLastStatus } from "util/history";
import { AUDIT_TRAIL_STATES } from "constants/transaction";
import {
  useActiveOrganization,
  useActiveOrganizationSettings,
} from "common/account/active_organization";
import LoadingIndicator from "common/core/loading_indicator";
import {
  Feature,
  MeetingEndedState,
  MortgageTransactionType,
  OrganizationTransactionVariant,
  OrgTransactionStates,
  OrganizationTransactionDetailedStatus,
  OrganizationTypeEnum,
  LimitTransactionDetails,
} from "graphql_globals";
import { SELF_SERVE_FORCE_COMPLETE } from "constants/feature_gates";
import { usePermissions, type OrganizationPermissions } from "common/core/current_user_role";
import { isIdentify } from "util/transaction";

import DefaultTransactionDetailsModalQuery, {
  type DefaultTransactionDetailsModal_node_OrganizationTransaction as OrganizationTransaction,
  type DefaultTransactionDetailsModal_node_OrganizationTransaction_bundle_meetings as BundleMeetings,
  type DefaultTransactionDetailsModal_viewer as Viewer,
  type DefaultTransactionDetailsModal_organization_Organization as TransactionDetailsOrganization,
} from "./index_query.graphql";
import { TransactionDetailsActions } from "./transaction_details_actions";
import { TransactionDetailsTabs } from "./tabs";
import { TransactionDetailsWrapper } from "./wrapper";

export type ChildRenderProps = {
  organization: TransactionDetailsOrganization;
  transaction: OrganizationTransaction;
  bundle: NonNullable<OrganizationTransaction["bundle"]>;
  meeting: null | BundleMeetings["edges"][number]["node"];
  finalizedDocumentOnly: boolean;
  viewer: Viewer;
  refetch: () => Promise<unknown>;
};
type Props = {
  children: (renderProps: ChildRenderProps) => ReactNode;
  withNotes?: boolean;
  withActivityLog?: boolean;
  className?: string;
  /** null means don't render anything */
  loadedMapFn?: (props: LoadedProps) => Partial<LoadedProps> | null;
};
type LoadedProps = Omit<Props, "loadedMapFn"> & {
  node: null | ChildRenderProps["transaction"];
  viewer: ChildRenderProps["viewer"];
  organization: ChildRenderProps["organization"];
  refetch: ChildRenderProps["refetch"];
};

function canResendTransaction(
  transaction: OrganizationTransaction,
  activeOrganizationId: string | null,
  hasPermissionFor: (permission: OrganizationPermissions) => boolean,
): boolean {
  const { transactionType, state, publicOrganization, transactionVariant } = transaction;
  const validState = [
    OrgTransactionStates.SENT,
    OrgTransactionStates.RECEIVED,
    OrgTransactionStates.ATTEMPTED,
  ].includes(state!);

  return (
    validState &&
    publicOrganization.id === activeOrganizationId &&
    !publicOrganization.suppressSignerEmails &&
    transactionType !== MortgageTransactionType.wet_sign &&
    transactionVariant !== OrganizationTransactionVariant.VERIFY &&
    hasPermissionFor("sendOrganizationTransactions")
  );
}

function getIncompleteMeeting(meetings: BundleMeetings) {
  const incompleteMeetings = meetings.edges.filter(
    (edge) => edge.node.endedState === MeetingEndedState.NOT_COMPLETED,
  );
  return incompleteMeetings.length
    ? incompleteMeetings.sort((a, b) =>
        dateComparator(b.node.timeFrame!.startedAt, a.node.timeFrame!.startedAt),
      )[0].node
    : null;
}

function useAnalytics(transaction: LoadedProps["node"]) {
  useAnalyticsContext(() => {
    if (!transaction) {
      return;
    }
    const { payer, transactionCreatorName, publicOrganization, guestEnabled, bundle } = transaction;

    return {
      transaction_creator_first_name: transactionCreatorName.firstName,
      transaction_creator_last_name: transactionCreatorName.lastName,
      document_count: bundle!.documents.totalCount,
      signer_count: bundle!.signers?.length,
      org_id: publicOrganization.id,
      payer,
      guest_enabled: guestEnabled,
    };
  });
}

function TransactionDetailsLoaded(props: LoadedProps) {
  const { node: transaction, viewer, refetch } = props;

  const { pathname } = useLocation();
  useAnalytics(transaction);
  const canUseCloneTransaction = useCloneTransaction();
  const activeOrganizationId = props.organization.id;
  const [showArchivedFailedBanner, setShowArchivedFailedBanner] = useState(false);
  const forceCompleteEnabled = useFeatureFlag(SELF_SERVE_FORCE_COMPLETE);
  const { hasPermissionFor } = usePermissions();
  const { limitTransactionDetails } = useActiveOrganizationSettings();
  if (!transaction) {
    return null;
  }

  const { state, canRecall, archived } = transaction;
  const bundle = transaction.bundle!;
  const { auditTrails } = bundle;
  const { organization: userOrganization } = viewer.user!;
  const closeRoute = getParentPathByOffset(pathname, 2);
  const showFullRecord = transaction.publicOrganization.featureList.includes(
    Feature.TRANSACTION_RECORD_FULL_ACCESS,
  );

  const showCloneButton =
    canUseCloneTransaction &&
    !(
      userOrganization?.organizationType === OrganizationTypeEnum.TITLE_AGENCY &&
      transaction.lenderName
    );

  const showArchiveButton =
    !archived &&
    showFullRecord &&
    [OrgTransactionStates.COMPLETED, OrgTransactionStates.COMPLETED_WITH_REJECTIONS].includes(
      state!,
    );
  const showUnarchiveButton =
    archived &&
    showFullRecord &&
    [OrgTransactionStates.COMPLETED, OrgTransactionStates.COMPLETED_WITH_REJECTIONS].includes(
      state!,
    );
  const showCancelButton =
    state !== OrgTransactionStates.COMPLETED &&
    transaction.publicOrganization.id === activeOrganizationId &&
    getLastStatus(auditTrails, state) !== AUDIT_TRAIL_STATES.MeetingInProgress &&
    ![
      OrganizationTransactionDetailedStatus.COMPLETE,
      OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS,
      OrganizationTransactionDetailedStatus.ESIGN_COMPLETE,
      OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE,
      OrganizationTransactionDetailedStatus.PARTIALLY_COMPLETE,
    ].includes(transaction.detailedStatus) &&
    hasPermissionFor("deleteOrganizationTransactions");
  const showRecallButton =
    AppSubdomains[CURRENT_PORTAL] !== "business" &&
    canRecall &&
    transaction.transactionType !== MortgageTransactionType.wet_sign &&
    hasPermissionFor("editOrganizationTransactions");
  const showDuplicateButton =
    hasPermissionFor("duplicateTransaction") &&
    userOrganization?.featureList.includes(Feature.DUPLICATE_TRANSACTIONS);
  const isIdentifyTransaction = isIdentify(transaction);
  const showForceCompleteButton =
    hasPermissionFor("selfServeForceComplete") &&
    forceCompleteEnabled &&
    ![OrgTransactionStates.COMPLETED, OrgTransactionStates.COMPLETED_WITH_REJECTIONS].includes(
      state!,
    ) &&
    !isIdentifyTransaction;

  function renderArchiveButton() {
    if (showArchiveButton) {
      return "archive";
    } else if (showUnarchiveButton) {
      return "unarchive";
    }
    return undefined;
  }

  const transactionDetailsActions = (
    <TransactionDetailsActions
      transaction={transaction}
      viewer={viewer}
      showCancelButton={showCancelButton}
      showRecallButton={showRecallButton}
      archiveButton={renderArchiveButton()}
      onArchiveFailure={() => setShowArchivedFailedBanner(true)}
      onArchiveSuccess={() => setShowArchivedFailedBanner(false)}
      showDuplicateButton={showDuplicateButton}
      showForceCompleteButton={showForceCompleteButton}
      showResendButton={canResendTransaction(transaction, activeOrganizationId, hasPermissionFor)}
      showCloneButton={showCloneButton}
      refetch={refetch}
    />
  );

  const transactionDetailsTabs =
    limitTransactionDetails === LimitTransactionDetails.NO_LIMIT ? (
      <TransactionDetailsTabs
        transaction={transaction}
        bundle={bundle}
        showFullRecord={showFullRecord}
        withNotes={props.withNotes}
        withActivityLog={props.withActivityLog}
        withDocuments={!isNotaryDocumentDownloadProhibited(viewer.user!.notaryProfile, transaction)}
      />
    ) : null;

  return (
    <TransactionDetailsWrapper
      closeRoute={newPathWithPreservedSearchParams(closeRoute)}
      className={props.className}
      transaction={transaction}
      showActionErrorMessage={showArchivedFailedBanner}
      transactionDetailsTabs={transactionDetailsTabs}
      transactionDetailsActions={transactionDetailsActions}
      viewerOrganization={props.viewer.user!.organization}
      refetch={refetch}
    >
      {props.children({
        bundle,
        meeting: getIncompleteMeeting(bundle.meetings),
        transaction,
        finalizedDocumentOnly: false,
        viewer,
        organization: props.organization,
        refetch,
      })}
    </TransactionDetailsWrapper>
  );
}

function TransactionDetails(props: Props) {
  const [activeOrganizationId] = useActiveOrganization();
  const { transactionID } = useParams();
  const { networkStatus, data, refetch } = useQuery(DefaultTransactionDetailsModalQuery, {
    variables: { organizationId: activeOrganizationId!, transactionId: transactionID! },
  });

  if (networkStatus === NetworkStatus.loading) {
    return <LoadingIndicator />;
  }

  const { organization, node, viewer } = data!;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }
  if (node?.__typename !== "OrganizationTransaction") {
    throw new Error(`Expected OrganizationTransaction, got ${node?.__typename}`);
  }

  const fullProps = { ...props, node, organization, viewer, refetch };
  if (props.loadedMapFn) {
    const mappedProps = props.loadedMapFn(fullProps);
    return mappedProps && <TransactionDetailsLoaded {...fullProps} {...mappedProps} />;
  }
  return <TransactionDetailsLoaded {...fullProps} />;
}

export default TransactionDetails;
